<template>
  <div>
    <template v-if="profileMatter">
      <h2>Centro de Calificaciones</h2>
      <Header
        class="pt-1 pb-4"
        :Matter="profileMatter"
        :section_id="section_id"
        :allows_crud="false"
      ></Header>
    </template>
    <div v-if="!enable_evaluate_view">
      <div>
        <h2>Registro de Notas y Edición:</h2>
      </div>
      <div class="flex-container">
        <div class="search noprint m-1 p-2 w-75">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="search-form"
              type="search"
              v-model="input_search"
              placeholder="Buscar Estudiantes..."
            >
            </b-form-input>
            <b-input-group-append>
              <b-button :disabled="!input_search" @click="input_search = ''"
                >Limpiar</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="column-selector noprint" v-if="displayList.length > 0">
          <b-button
            :id="`col-display-1`"
            class="col-display-1 px-5"
            variant="none"
            size="sm"
            >Visualizar columnas</b-button
          >
          <b-popover
            :id="`columns-popover-1`"
            :target="`col-display-1`"
            placement="bottom"
            class="col-popover-1"
            triggers="click blur"
          >
            <template v-for="instrument in displayList">
              <div
                class="column-check noprint"
                :key="'question_tests' + instrument.index"
              >
                <b-form-checkbox
                  :id="`column-check-${instrument.index}`"
                  name="checkbox-1"
                  :value="instrument.index"
                  v-model="check_instrument"
                >
                  <b-icon
                    v-if="instrument.type == 'question_test'"
                    class="ml-1 mt-1"
                    icon="journal-richtext"
                  ></b-icon>
                  <b-icon v-else class="ml-1 mt-1" icon="table"></b-icon>
                  {{ instrument.title }}
                </b-form-checkbox>
              </div>
            </template>
          </b-popover>
        </div>
      </div>
      <b-table-simple bordered small responsive class="table-bordered">
        <b-thead>
          <b-tr>
            <b-th rowspan="2" class="th-class border-right">Estudiante</b-th>
            <b-th
              v-for="evaluation in list.evaluations"
              :key="evaluation.id"
              class="th-class border-right"
              :colspan="
                showColumn(
                  evaluation.observation_instruments,
                  'observation_instrument'
                ).length +
                showColumn(evaluation.question_tests, 'question_test').length +
                1
              "
              ><div
                @click="showEvaluateView(evaluation.id)"
                class="div-href"
                v-b-tooltip.v-secondary.noninteractive.hover="
                  'Ir a la Evaluación'
                "
              >
                <span
                  v-b-tooltip.right.v-secondary.noninteractive.hover="
                    'Puntaje máximo: ' + evaluation.score
                  "
                >
                  {{ evaluation.name }} - {{ evaluation.weighing }}%
                </span>
              </div>
            </b-th>
            <b-th rowspan="2" class="th-class border-right">Nota Final</b-th>
          </b-tr>
          <b-tr>
            <template v-for="evaluation in list.evaluations">
              <b-th
                class="th-class th-border"
                v-for="instrument in showColumn(
                  evaluation.question_tests,
                  'question_test'
                )"
                :key="evaluation.id + ':' + instrument.id"
                ><span :key="evaluation.id + ':' + instrument.id">
                  <!-- v-b-tooltip.v-secondary.noninteractive.hover="
                    'Puntaje máximo: ' + instrument.score
                  " -->
                  <!-- v-b-tooltip.v-secondary.noninteractive.hover.right="
                      'No publicada'
                    " -->
                  <b-icon
                    v-b-tooltip.v-secondary.noninteractive.hover.left="
                      'Instrumento Escrito'
                    "
                    class="ml-1 mt-1"
                    icon="journal-richtext"
                  ></b-icon>
                  <span
                    v-b-tooltip.v-secondary.noninteractive.hover="
                      'Puntaje máximo: ' + instrument.score
                    "
                  >
                    {{ instrument.title }} - {{ instrument.weighing | Round }}%
                  </span>
                  <b-icon
                    class="ml-1 noprint"
                    v-if="!instrument.all_published"
                    v-b-tooltip.v-secondary.noninteractive.hover.right="
                      'Publicar Notas'
                    "
                    icon="journal-text"
                    @click="
                      fetchInstrumentPublishAsTrue(
                        'question_test',
                        instrument.id
                      )
                    "
                  ></b-icon>
                </span>
              </b-th>
              <b-th
                class="th-class"
                v-for="instrument in showColumn(
                  evaluation.observation_instruments,
                  'observation_instrument'
                )"
                :key="evaluation.id + ':' + instrument.id"
              >
                <span :key="evaluation.id + ':' + instrument.id">
                  <b-icon
                    v-b-tooltip.v-secondary.noninteractive.hover.left="
                      'Instrumento de Observación'
                    "
                    class="ml-1 mt-1"
                    icon="table"
                  ></b-icon>
                  <span
                    v-b-tooltip.v-secondary.noninteractive.hover="
                      'Puntaje máximo: ' + instrument.score
                    "
                  >
                    {{ instrument.title }} - {{ instrument.weighing | Round }}%
                  </span>
                  <b-icon
                    class="ml-1"
                    v-if="!instrument.all_published"
                    v-b-tooltip.v-secondary.noninteractive.hover.right="
                      'Publicar Notas'
                    "
                    @click="
                      fetchInstrumentPublishAsTrue(
                        'observation_instrument',
                        instrument.id
                      )
                    "
                    icon="journal-text"
                  ></b-icon>
                </span>
              </b-th>
              <b-th class="th-class border-right" :key="evaluation.id">
                Nota {{ evaluation.name }}
              </b-th>
            </template>
          </b-tr>
        </b-thead>
        <b-tbody>
          <tr v-for="student in studentList" :key="student.student_id">
            <td class="td-class border-right">
              {{ student.first_name }} {{ student.last_name }}
            </td>
            <template v-for="evaluation in list.evaluations">
              <td
                class="td-class"
                v-for="instrument in showColumn(
                  evaluation.question_tests,
                  'question_test'
                )"
                :key="evaluation.id + ':' + instrument.id"
                v-bind:class="
                  getStudentScore(
                    student,
                    'question_test',
                    evaluation,
                    instrument
                  ).student_automated_grade.toFixed(1) == null
                    ? ''
                    : getStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      ).student_automated_grade.toFixed(1) <=
                      list.ranges[0].insufficient_to - 0.1
                    ? 'bg-red'
                    : getStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      ).student_automated_grade.toFixed(1) <=
                      list.ranges[1].sufficient_to - 0.1
                    ? 'bg-yellow'
                    : 'bg-green'
                "
              >
                <!-- v-bind:class="{
                    'publish-class':
                      getStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      ).publish == false,
                  }" -->
                <div class="d-flex" v-if="!allows_crud">
                  <div
                    class="w-100"
                    v-if="
                      getStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      ).is_started_date == null &&
                      getStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      ).student_manual_score == null &&
                      getStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      ).student_automated_score == 0
                    "
                  >
                    <span
                      v-b-tooltip.v-secondary.noninteractive.hover.right="
                        'Instrumento no contestado'
                      "
                      >-
                    </span>
                  </div>
                  <span
                    v-else
                    class="w-100"
                    v-b-tooltip.v-secondary.noninteractive.hover="
                      'Puntaje obtenido: ' +
                      +(
                        Math.round(
                          Number(
                            getStudentScore(
                              student,
                              'question_test',
                              evaluation,
                              instrument
                            ).student_manual_score == null
                              ? getStudentScore(
                                  student,
                                  'question_test',
                                  evaluation,
                                  instrument
                                ).student_automated_score
                              : getStudentScore(
                                  student,
                                  'question_test',
                                  evaluation,
                                  instrument
                                ).student_manual_score
                          ) + 'e+2'
                        ) + 'e-2'
                      ) +
                      '/' +
                      getStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      ).instrument_score
                    "
                  >
                    {{
                      parseFloat(
                        getStudentScore(
                          student,
                          "question_test",
                          evaluation,
                          instrument
                        ).student_automated_grade.toFixed(2)
                      )
                    }}
                  </span>
                  <b-icon
                    v-if="
                      getStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      ).publish == true
                    "
                    class="ml-1 mt-1"
                    v-b-tooltip.v-secondary.noninteractive.hover.right="
                      'Publicada'
                    "
                    icon="eye"
                  ></b-icon>
                  <b-icon
                    v-else
                    class="ml-1 mt-1"
                    v-b-tooltip.v-secondary.noninteractive.hover.right="
                      'No publicada'
                    "
                    icon="eye-slash"
                  ></b-icon>
                  <b-icon
                    v-if="
                      getStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      ).is_started_date == null
                    "
                    v-b-tooltip.v-secondary.noninteractive.hover.right="
                      'Instrumento no contestado.'
                    "
                    class="ml-1 mt-1"
                    icon="journal-x"
                  ></b-icon>
                  <b-icon
                    v-else
                    class="ml-1 mt-1"
                    v-b-tooltip.v-secondary.noninteractive.hover.right="
                      'El instrumento se inicio el ' +
                      $moment(
                        getStudentScore(
                          student,
                          'question_test',
                          evaluation,
                          instrument
                        ).is_started_date
                      ).format('DD/MM/YYYY HH:mm')
                    "
                    icon="journal-check"
                  ></b-icon>
                </div>
                <span v-else>
                  <div
                    class="d-flex"
                    v-if="
                      getStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      ).can_publish_and_edit_score
                    "
                  >
                    <b-form-input
                      :ref="
                        'student:' +
                        student.student_id +
                        'evaluation:' +
                        evaluation.id +
                        'question_test:' +
                        instrument.id
                      "
                      size="sm"
                      type="number"
                      step="0.01"
                      :max="institution.max_grade"
                      :min="institution.min_grade"
                      class="m-0 p-0 w-100"
                      :value="
                        parseFloat(
                          getStudentScore(
                            student,
                            'question_test',
                            evaluation,
                            instrument
                          ).student_automated_grade.toFixed(2)
                        )
                      "
                    >
                    </b-form-input>
                    <!-- @update="
                      patchStudentScore(
                        student,
                        'question_test',
                        evaluation,
                        instrument
                      )
                    " -->
                    <b-icon
                      class="ml-1 mt-1"
                      v-b-tooltip.v-secondary.noninteractive.hover.right="
                        'Guardar'
                      "
                      icon="upload"
                      @click="
                        patchStudentScore(
                          student,
                          'question_test',
                          evaluation,
                          instrument
                        )
                      "
                    ></b-icon>
                    <b-icon
                      class="ml-1 mt-1"
                      v-b-tooltip.v-secondary.noninteractive.hover="
                        'Utilizar Puntaje automático: ' +
                        +(
                          Math.round(
                            Number(
                              getStudentScore(
                                student,
                                'question_test',
                                evaluation,
                                instrument
                              ).student_automated_score
                            ) + 'e+2'
                          ) + 'e-2'
                        ) +
                        '/' +
                        getStudentScore(
                          student,
                          'question_test',
                          evaluation,
                          instrument
                        ).instrument_score
                      "
                      @click="
                        patchManualScore(
                          getStudentScore(
                            student,
                            'question_test',
                            evaluation,
                            instrument
                          ).manual_score_id,
                          { score: null }
                        )
                      "
                      icon="arrow-repeat"
                    ></b-icon>
                  </div>
                  <span v-else
                    >{{
                      parseFloat(
                        getStudentScore(
                          student,
                          "question_test",
                          evaluation,
                          instrument
                        ).student_automated_grade.toFixed(2)
                      )
                    }}
                  </span>
                </span>
              </td>
              <td
                class="td-class"
                v-for="instrument in showColumn(
                  evaluation.observation_instruments,
                  'observation_instrument'
                )"
                :key="evaluation.id + ':' + instrument.id"
                v-bind:class="
                  getStudentScore(
                    student,
                    'observation_instrument',
                    evaluation,
                    instrument
                  ).student_automated_grade.toFixed(1) == null
                    ? ''
                    : getStudentScore(
                        student,
                        'observation_instrument',
                        evaluation,
                        instrument
                      ).student_automated_grade.toFixed(1) <=
                      list.ranges[0].insufficient_to - 0.1
                    ? 'bg-red'
                    : getStudentScore(
                        student,
                        'observation_instrument',
                        evaluation,
                        instrument
                      ).student_automated_grade.toFixed(1) <=
                      list.ranges[1].sufficient_to - 0.1
                    ? 'bg-yellow'
                    : 'bg-green'
                "
              >
                <div v-if="!allows_crud" class="d-flex">
                  <span
                    class="w-100"
                    v-b-tooltip.v-secondary.noninteractive.hover="
                      'Puntaje obtenido: ' +
                      +(
                        Math.round(
                          Number(
                            getStudentScore(
                              student,
                              'observation_instrument',
                              evaluation,
                              instrument
                            ).student_manual_score == null
                              ? getStudentScore(
                                  student,
                                  'observation_instrument',
                                  evaluation,
                                  instrument
                                ).student_automated_score
                              : getStudentScore(
                                  student,
                                  'observation_instrument',
                                  evaluation,
                                  instrument
                                ).student_manual_score
                          ) + 'e+2'
                        ) + 'e-2'
                      ) +
                      '/' +
                      getStudentScore(
                        student,
                        'observation_instrument',
                        evaluation,
                        instrument
                      ).instrument_score
                    "
                  >
                    {{
                      parseFloat(
                        getStudentScore(
                          student,
                          "observation_instrument",
                          evaluation,
                          instrument
                        ).student_automated_grade.toFixed(2)
                      )
                    }} </span
                  ><b-icon
                    v-if="
                      getStudentScore(
                        student,
                        'observation_instrument',
                        evaluation,
                        instrument
                      ).publish == true
                    "
                    class="ml-1 mt-1"
                    v-b-tooltip.v-secondary.noninteractive.hover.right="
                      'Publicada'
                    "
                    icon="eye"
                  ></b-icon>
                  <b-icon
                    v-else
                    class="ml-1 mt-1"
                    v-b-tooltip.v-secondary.noninteractive.hover.right="
                      'No publicada'
                    "
                    icon="eye-slash"
                  ></b-icon>
                </div>
                <span v-else>
                  <div
                    class="d-flex"
                    v-if="
                      getStudentScore(
                        student,
                        'observation_instrument',
                        evaluation,
                        instrument
                      ).can_publish_and_edit_score
                    "
                  >
                    <b-form-input
                      :ref="
                        'student:' +
                        student.student_id +
                        'evaluation:' +
                        evaluation.id +
                        'observation_instrument:' +
                        instrument.id
                      "
                      size="sm"
                      step="0.01"
                      :max="institution.max_grade"
                      :min="institution.min_grade"
                      type="number"
                      :value="
                        parseFloat(
                          getStudentScore(
                            student,
                            'observation_instrument',
                            evaluation,
                            instrument
                          ).student_automated_grade.toFixed(2)
                        )
                      "
                      class="m-0 p-0 w-100"
                    ></b-form-input>
                    <b-icon
                      class="ml-1 mt-1"
                      v-b-tooltip.v-secondary.noninteractive.hover.right="
                        'Guardar'
                      "
                      icon="upload"
                      @click="
                        patchStudentScore(
                          student,
                          'observation_instrument',
                          evaluation,
                          instrument
                        )
                      "
                    ></b-icon>
                    <b-icon
                      class="ml-1 mt-1"
                      v-b-tooltip.v-secondary.noninteractive.hover="
                        'Utilizar Puntaje automático: ' +
                        +(
                          Math.round(
                            Number(
                              getStudentScore(
                                student,
                                'observation_instrument',
                                evaluation,
                                instrument
                              ).student_automated_score
                            ) + 'e+2'
                          ) + 'e-2'
                        ) +
                        '/' +
                        getStudentScore(
                          student,
                          'observation_instrument',
                          evaluation,
                          instrument
                        ).instrument_score
                      "
                      @click="
                        patchManualScore(
                          getStudentScore(
                            student,
                            'observation_instrument',
                            evaluation,
                            instrument
                          ).manual_score_id,
                          { score: null }
                        )
                      "
                      icon="arrow-repeat"
                    ></b-icon>
                  </div>
                  <span v-else>
                    {{
                      parseFloat(
                        getStudentScore(
                          student,
                          "observation_instrument",
                          evaluation,
                          instrument
                        ).student_automated_grade.toFixed(2)
                      )
                    }}
                  </span>
                </span>
              </td>
              <td
                class="td-class border-right"
                :key="evaluation.id"
                v-bind:class="
                  getStudentScore(
                    student,
                    'evaluation',
                    evaluation
                  ).student_automated_grade.toFixed(1) == null
                    ? ''
                    : getStudentScore(
                        student,
                        'evaluation',
                        evaluation
                      ).student_automated_grade.toFixed(1) <=
                      list.ranges[0].insufficient_to - 0.1
                    ? 'bg-red'
                    : getStudentScore(
                        student,
                        'evaluation',
                        evaluation
                      ).student_automated_grade.toFixed(1) <=
                      list.ranges[1].sufficient_to - 0.1
                    ? 'bg-yellow'
                    : 'bg-green'
                "
              >
                <span
                  v-if="!allows_crud"
                  v-b-tooltip.v-secondary.noninteractive.hover="
                    'Puntaje obtenido: ' +
                    +(
                      Math.round(
                        Number(
                          getStudentScore(student, 'evaluation', evaluation)
                            .student_manual_score == null
                            ? getStudentScore(student, 'evaluation', evaluation)
                                .student_automated_score
                            : getStudentScore(student, 'evaluation', evaluation)
                                .student_manual_score
                        ) + 'e+2'
                      ) + 'e-2'
                    ) +
                    '/' +
                    getStudentScore(student, 'evaluation', evaluation)
                      .evaluation_score
                  "
                >
                  {{
                    parseFloat(
                      getStudentScore(
                        student,
                        "evaluation",
                        evaluation
                      ).student_automated_grade.toFixed(2)
                    )
                  }}
                </span>
                <span v-else>
                  <div class="d-flex">
                    <b-form-input
                      :ref="
                        'student:' +
                        student.student_id +
                        'evaluation:' +
                        evaluation.id
                      "
                      size="sm"
                      step="0.01"
                      :max="institution.max_grade"
                      :min="institution.min_grade"
                      type="number"
                      :value="
                        parseFloat(
                          getStudentScore(
                            student,
                            'evaluation',
                            evaluation
                          ).student_automated_grade.toFixed(2)
                        )
                      "
                      class="m-0 p-0 w-100"
                    ></b-form-input>
                    <!-- @update="
                      patchStudentScore(student, 'evaluation', evaluation)
                    " -->
                    <b-icon
                      class="ml-1 mt-1"
                      v-b-tooltip.v-secondary.noninteractive.hover.right="
                        'Guardar'
                      "
                      icon="upload"
                      @click="
                        patchStudentScore(student, 'evaluation', evaluation)
                      "
                    ></b-icon>
                    <b-icon
                      class="ml-1 mt-1"
                      v-b-tooltip.v-secondary.noninteractive.hover="
                        'Nota automática'
                      "
                      @click="
                        patchManualScore(
                          getStudentScore(student, 'evaluation', evaluation)
                            .manual_score_id,
                          { score: null }
                        )
                      "
                      icon="arrow-repeat"
                    ></b-icon>
                  </div>
                </span>
              </td>
            </template>
            <td
              v-bind:class="
                getFinalScore(student) == 0
                  ? ''
                  : getFinalScore(student) <=
                    list.ranges[0].insufficient_to - 0.1
                  ? 'bg-red'
                  : getFinalScore(student) <= list.ranges[1].sufficient_to - 0.1
                  ? 'bg-yellow'
                  : 'bg-green'
              "
            >
              {{ getFinalScore(student) }}
            </td>
          </tr>
          <tr v-if="studentList.length == 0">
            <td :colspan="rowColspan()">
              No hay datos que coincidan con su búsqueda.
            </td>
          </tr>
        </b-tbody>
      </b-table-simple>
      <template v-if="list && list.ranges">
        <div class="report-ranges-caption mb-2">
          <div class="report-ranges-header">RANGOS DE NOTAS FINALES</div>

          <div class="report-range">
            <span class="range-label"> Insuficiente </span>
            <span class="range-label">
              Cantidad: {{ list.ranges[0].insufficient_amount }}</span
            >
            <span class="range-label">
              Porcentaje: {{ list.ranges[0].insufficient_percentage }}</span
            >
            <div class="range-value-red">
              {{ list.ranges[0].insufficient_from_including }} -
              {{ list.ranges[0].insufficient_to - 0.1 }}
            </div>
          </div>
          <div class="report-range">
            <span class="range-label"> Suficiente </span>
            <span class="range-label">
              Cantidad: {{ list.ranges[1].sufficient_amount }}</span
            >
            <span class="range-label">
              Porcentaje: {{ list.ranges[1].sufficient_percentage }}</span
            >
            <div class="range-value-yellow">
              {{ list.ranges[1].sufficient_from_including }} -
              {{ list.ranges[1].sufficient_to - 0.1 }}
            </div>
          </div>
          <div class="report-range">
            <span class="range-label"> Destacada </span>
            <span class="range-label">
              Cantidad: {{ list.ranges[2].outstanding_amount }}</span
            >
            <span class="range-label">
              Porcentaje: {{ list.ranges[2].outstanding_percentage }}</span
            >
            <div class="range-value-green">
              {{ list.ranges[2].outstanding_from_including }} -
              {{ list.ranges[2].outstanding_to_including }}
            </div>
          </div>
        </div>
      </template>
      <div class="info">
        <h5 class="info-title">
          <b-icon icon="info-circle"></b-icon>Simbología / Significados
        </h5>
        <div class="info-container">
          <div class="d-flex" v-if="allows_crud">
            <b-icon icon="upload" class="mr-1"></b-icon>
            <p style="margin-top: -3px">
              Guarda la nota ingresada del instrumento o Evaluación.
            </p>
          </div>
          <div v-if="allows_crud">
            <div class="d-flex mt-1 ml-4">
              <b-icon icon="arrow-repeat" class="mt-1 mr-1"></b-icon>
              <strong> Evaluación:</strong> Aplica la suma de puntajes de los
              instrumentos asociados.
            </div>
            <div class="d-flex mt-1 ml-4">
              <b-icon icon="arrow-repeat" class="mt-1 mr-1"></b-icon>
              <strong> Instrumento:</strong> Aplica la suma de puntajes de las
              preguntas asociadas
            </div>
          </div>
          <div>
            <div class="d-flex mt-1">
              <b-icon icon="journal-richtext" class="mr-1"></b-icon>
              <p>Instrumento Escrito.</p>
            </div>
            <div class="d-flex mt-1">
              <b-icon icon="table" class="mr-1"></b-icon>
              <p>Instrumento de Observación.</p>
            </div>
            <div class="d-flex mt-1">
              <b-icon icon="journal-text" class="mr-1"></b-icon>
              <p>
                Permite al docente publicar todas las notas simultáneamente.
              </p>
            </div>
            <div class="d-flex mt-1">
              <b-icon icon="eye"></b-icon>
              <b-icon icon="eye-slash"></b-icon>
              <span class="ml-2">
                Indica si la nota está publicada para el estudiante.
              </span>
            </div>
            <div class="d-flex mt-1">
              <b-icon icon="journal-check"></b-icon>
              <b-icon icon="journal-x"></b-icon>
              <span class="ml-2">
                Indica si el estudiante contestó el Instrumento Escrito.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <EvaluationView
        :evaluation_id="current_evaluation_id"
        :section_id="section_id"
        :period_id="section.period"
        @backToView="slotBackToView"
      ></EvaluationView>
    </div>
    <DashboardCustomFab
      class="noprint"
      :section_id="section_id"
      :show_full_grading="false"
    ></DashboardCustomFab>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "FullGradingCenterView",
  components: {
    Header: () => import("@/components/teaching/Planification/Header"),
    EvaluationView: () => import("../Test/Evaluate/EvaluateView.vue"),
    DashboardCustomFab: () =>
      import("@/components/dashboard/DashboardCustomFab"),
  },
  data() {
    return {
      section_id: Number(this.$route.params.section_id),
      list: [],
      enable_evaluate_view: false,
      current_evaluation_id: null,
      input_search: "",
      displayList: [],
      check_instrument: [],
    };
  },
  computed: {
    ...mapGetters({
      evaluation_categories: names.EVALUATION_CATEGORIES,
      sections: names.SECTIONS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      questionTests: names.QUESTION_TESTS,
      rubrics: names.OBSERVATION_INSTRUMENTS,
      matters: names.MATTERS,
      users: names.USERS,
      evaluations: names.EVALUATIONS,
      institution: "getInstitution",
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("teaching.change_evaluation");
      if (
        this.section &&
        this.institution &&
        this.institution.actual_period != null &&
        this.institution.actual_period.id == this.section.period
      ) {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
        if (has_permission) return has_permission;
        return false;
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
    section() {
      if (!this.section_id) return [];
      return this.sections.find((x) => x.id == this.section_id);
    },
    studentList() {
      if (this.list.length == 0) return [];
      if (this.input_search == "") return this.list.student_list;
      return this.list.student_list.filter((x) =>
        this.$filtered(
          String(x.first_name + " " + x.last_name),
          String(this.input_search)
        )
      );
    },
    profileMatter() {
      if (!this.section) return null;
      return this.egress_profiles_matters.find(
        (x) => x.id == this.section.egress_profile_matter
      );
    },
    evaluationsList() {
      if (!this.section) return [];
      return this.evaluations.filter((x) =>
        x.sections.includes(this.section.id)
      );
    },
    questionTestsList() {
      if (!this.evaluationsList) return [];
      let evaluations = this.evaluationsList.map((object) => object.id);
      evaluations = [...new Set(evaluations)];
      return this.questionTests.filter((x) =>
        evaluations.includes(x.evaluation)
      );
    },
    observationInstrumentList() {
      if (!this.evaluationsList) return [];
      let evaluations = this.evaluationsList.map((object) => object.id);
      evaluations = [...new Set(evaluations)];
      return this.rubrics.filter((x) => evaluations.includes(x.evaluation));
    },
  },
  methods: {
    showColumn(instruments, type) {
      let list = [];
      const instrument_ids = this.displayList
        .filter(
          (x) => this.check_instrument.includes(x.index) && x.type == type
        )
        .map((object) => object.id);
      if (type == "question_test") {
        list = instruments.filter((x) => instrument_ids.includes(x.id));
      }
      if (type == "observation_instrument") {
        list = instruments.filter((x) => instrument_ids.includes(x.id));
      }
      return list;
    },
    fetchFullGrading() {
      this.displayList = [];
      this.check_instrument = [];
      this.$restful
        .Get(
          `/teaching/professor_section_grades_center/?section=${this.section_id}`
        )
        .then((response) => {
          this.list = response;
          let index = 0;
          response.evaluations.forEach((evaluation) => {
            evaluation.question_tests.forEach((instrument) => {
              this.displayList.push({
                index: index,
                id: instrument.id,
                title: instrument.title,
                type: "question_test",
              });
              this.check_instrument.push(index);
              index += 1;
            });
            evaluation.observation_instruments.forEach((instrument) => {
              this.displayList.push({
                index: index,
                id: instrument.id,
                title: instrument.title,
                type: "observation_instrument",
              });
              this.check_instrument.push(index);
              index += 1;
            });
          });
        });
    },
    fetchInstrumentPublishAsTrue(label, instrument_id) {
      if (label == "question_test") {
        this.$restful
          .Get(
            `/teaching/section_instrument_publish_as_true/?section=${this.section_id}&question_test=${instrument_id}`
          )
          .then(() => {
            toast("Notas Publicadas.");
            this.fetchFullGrading();
          });
      } else if (label == "observation_instrument") {
        this.$restful
          .Get(
            `/teaching/section_instrument_publish_as_true/?section=${this.section_id}&observation_instrument=${instrument_id}`
          )
          .then(() => {
            toast("Notas Publicadas.");
            this.fetchFullGrading();
          });
      }
    },
    rowColspan() {
      let count = 2;
      if (this.list.length != 0) {
        this.list.evaluations.forEach((x) => {
          count +=
            x.question_tests.length + x.observation_instruments.length + 1;
        });
        return count;
      }
      return count;
    },
    getFinalScore(student) {
      let score = 0;
      let weighing = 0;
      student.evaluations.forEach((evaluation) => {
        weighing += evaluation.evaluation_weighing;
        score +=
          evaluation.student_automated_grade *
          (evaluation.evaluation_weighing / 100);
      });
      score += ((100 - weighing) / 100) * this.institution.min_grade;
      if (score < this.institution.min_grade)
        return parseFloat(this.institution.min_grade);
      if (score > this.institution.max_grade)
        return parseFloat(this.institution.max_grade);
      return score.toFixed(1);
    },
    getScoreEvaluation(score, evaluation_max_score) {
      if (this.institution) {
        let range = this.institution.max_grade - this.institution.min_grade;
        let absolute_grade = score - this.institution.min_grade;
        let manual_score = (absolute_grade * evaluation_max_score) / range;
        return manual_score;
      }
      return 0;
    },
    getScore(score, evaluation_max_score) {
      if (this.institution) {
        const appr_grade = this.institution.appr_grade;
        const exigence_percentage = this.institution.exigence_percentage / 100;
        const min_grade = this.institution.min_grade;
        const max_grade = this.institution.max_grade;
        const max_score = evaluation_max_score;
        const manual_grade = score;
        let manual_score = 0;
        if (manual_grade < appr_grade)
          manual_score =
            ((manual_grade - min_grade) * exigence_percentage * max_score) /
            (appr_grade - min_grade);
        else
          manual_score =
            ((manual_grade - appr_grade) *
              max_score *
              (1 - exigence_percentage)) /
              (max_grade - appr_grade) +
            exigence_percentage * max_score;
        if (manual_score > max_score) return max_score;
        else if (manual_score < 0) return 0;
        else return parseFloat(manual_score.toFixed(2));
      }
      return 0;
    },
    patchManualScore(manual_score_id, item) {
      if (manual_score_id != null) {
        this.$restful
          .Patch(`/evaluation/manual-score/${manual_score_id}/`, item)
          .then(() => {
            this.fetchFullGrading();
            toast("Nota actualizada.");
          });
      }
    },
    patchStudentScore(student, label, evaluation, instrument) {
      if (label == "question_test") {
        let score = parseFloat(
          this.$refs[
            "student:" +
              student.student_id +
              "evaluation:" +
              evaluation.id +
              "question_test:" +
              instrument.id
          ][0].localValue
        );
        if (
          score >= this.institution.min_grade &&
          score <= this.institution.max_grade
        ) {
          let index1 = student.evaluations.findIndex(
            (x) => x.evaluation_id == evaluation.id
          );
          if (index1 != -1) {
            let index2 = student.evaluations[index1].instruments.findIndex(
              (x) =>
                x.instrument_id == instrument.id &&
                x.instrument_type == "question_test"
            );
            if (index2 != -1) {
              this.patchManualScore(
                student.evaluations[index1].instruments[index2].manual_score_id,
                {
                  score: this.getScore(
                    score,
                    student.evaluations[index1].instruments[index2]
                      .instrument_score
                  ),
                }
              );
            }
          }
        }
      }
      if (label == "observation_instrument") {
        let score =
          this.$refs[
            "student:" +
              student.student_id +
              "evaluation:" +
              evaluation.id +
              "observation_instrument:" +
              instrument.id
          ][0].localValue;
        if (
          score >= this.institution.min_grade &&
          score <= this.institution.max_grade
        ) {
          let index1 = student.evaluations.findIndex(
            (x) => x.evaluation_id == evaluation.id
          );
          if (index1 != -1) {
            let index2 = student.evaluations[index1].instruments.findIndex(
              (x) =>
                x.instrument_id == instrument.id &&
                x.instrument_type == "observation_instrument"
            );
            if (index2 != -1) {
              this.patchManualScore(
                student.evaluations[index1].instruments[index2].manual_score_id,
                {
                  score: this.getScore(
                    score,
                    student.evaluations[index1].instruments[index2]
                      .instrument_score
                  ),
                }
              );
            }
          }
        }
      }
      if (label == "evaluation") {
        let score =
          this.$refs[
            "student:" + student.student_id + "evaluation:" + evaluation.id
          ][0].localValue;
        if (
          score >= this.institution.min_grade &&
          score <= this.institution.max_grade
        ) {
          let index1 = student.evaluations.findIndex(
            (x) => x.evaluation_id == evaluation.id
          );
          if (index1 != -1) {
            this.patchManualScore(student.evaluations[index1].manual_score_id, {
              score: this.getScoreEvaluation(
                score,
                student.evaluations[index1].evaluation_score
              ),
            });
          }
        }
      }
    },
    getStudentScore(student, label, evaluation, instrument) {
      if (label == "question_test") {
        let index = student.evaluations.findIndex(
          (x) => x.evaluation_id == evaluation.id
        );
        if (index != -1) {
          let student_score = student.evaluations[index].instruments.find(
            (x) =>
              x.instrument_id == instrument.id &&
              x.instrument_type == "question_test"
          );
          // return student_score.student_automated_grade.toFixed(1);
          return student_score;
        }
      }
      if (label == "observation_instrument") {
        let index = student.evaluations.findIndex(
          (x) => x.evaluation_id == evaluation.id
        );
        if (index != -1) {
          let student_score = student.evaluations[index].instruments.find(
            (x) =>
              x.instrument_id == instrument.id &&
              x.instrument_type == "observation_instrument"
          );
          // return student_score.student_automated_grade.toFixed(1);
          return student_score;
        }
      }
      if (label == "evaluation") {
        let student_score = student.evaluations.find(
          (x) => x.evaluation_id == evaluation.id
        );
        // return student_score.student_automated_grade.toFixed(1);
        return student_score;
      }
      return {
        student_automated_grade: null,
        publish: true,
        student_automated_score: 0,
        evaluation_score: 0,
        instrument_score: 0,
        is_started_date: null,
        manual_score_id: null,
      };
    },
    showEvaluateView(evaluation_id) {
      this.current_evaluation_id = evaluation_id;
      this.enable_evaluate_view = !this.enable_evaluate_view;
    },
    slotBackToView() {
      this.fetchFullGrading();
      this.enable_evaluate_view = !this.enable_evaluate_view;
    },
  },
  created() {
    this.fetchFullGrading();
    this.$store
      .dispatch(names.FETCH_SECTION, this.section_id)
      .then((section) => {
        this.$store
          .dispatch(
            names.FETCH_EGRESS_PROFILE_MATTER,
            section.egress_profile_matter
          )
          .then((matter) => {
            this.$store.dispatch(names.FETCH_MATTER, matter.matter);
          });
        this.$store
          .dispatch(names.FETCH_EVALUATIONS_BY_SECTION, this.section_id)
          .then((response) => {
            response.forEach((x) => {
              this.$store.dispatch(names.FETCH_QUESTION_TESTS, x.id);
              this.$store.dispatch(names.FETCH_OBSERVATION_INSTRUMENTS, x.id);
              this.$store.dispatch(names.FETCH_SECTION_EVALUATIONS, x.id);
            });
          });
      });
  },
};
</script>
<style scoped>
.th-class {
  background-color: var(--primary-color) !important;
  vertical-align: middle !important;
}
.bg-red {
  background-color: #f19292 !important;
}
.bg-yellow {
  background-color: #fff1ac !important;
}
.bg-green {
  background-color: #bbeaa6 !important;
}
.td-class {
  vertical-align: middle;
}
.border-right {
  border-right: 1px solid black !important;
}
/* .border-right:last-of-type {
  border-right: none !important;
} */
.table-bordered {
  border: 1px solid black !important;
}
.column-selector {
  margin-top: 0.6em;
  margin-left: auto;
  margin-right: 1em;
}
.publish-class {
  color: silver;
}
.flex-container {
  display: flex;
  margin-bottom: 1em;
}
.col-display-1,
.col-display-2 {
  min-width: 154px;
  margin-left: 2em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.col-display-1:hover,
.col-display-2:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
  transition: all 0.3s;
}
.col-popover-1 {
  z-index: 1010 !important;
}
.column-check {
  text-align: left;
}
/* Info */
.info {
  background-color: var(--primary-color);
  border-radius: 10px;
  margin-top: 1.8%;
  padding: 0 2% 2% 2%;
}
.info-container {
  background-color: var(--secondary-font-color);
  padding: 1rem;
  border-radius: 10px;
}
.info-title {
  text-align: left;
  padding-top: 1%;
  margin-bottom: 2%;
}
.info-title .b-icon {
  margin-right: 1%;
}
.info .b-icon {
  max-width: 2rem;
  margin-left: 1%;
}
.info p {
  text-align: left;
  margin-top: -0.4%;
  margin-bottom: 0;
}
.div-href {
  color: #007bff;
}
.div-href:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
.report-ranges-caption {
  width: 100%;
  border: 1px solid var(--primary-hover-color);
}
.report-ranges-header {
  text-align: left;
  padding: 0.5em 1em;
  padding-left: 2em;
  background: var(--primary-hover-color);
  /* color: var(--secondary-font-color); */
  font-weight: bold;
}
.report-range {
  display: flex;
  margin-left: 0;
  padding: 0.5em 4em;
}
.range-label {
  font-weight: bold;
  text-align: left;
  width: 25%;
}
.report-range .range-value-red {
  background: #f19292;
  width: 25%;
}
.report-range .range-value-yellow {
  background: #fff1ac;
  width: 25%;
}
.report-range .range-value-green {
  background: #bbeaa6;
  width: 25%;
}
@media (max-width: 650px) {
  .report-range {
    display: block;
  }
  .report-range .range-value-red,
  .report-range .range-value-yellow,
  .report-range .range-value-green {
    width: 100%;
  }
}
</style>